<template>
  <div class="container">
    <div class="info">
      <div class="font-weight-bold">
        Дата изменения
      </div>
      <div class="crm-info-text text-break">
        {{ formatDate(change.date) }}
      </div>
    </div>
    <div class="info-description">
      <!-- <div class="d-flex mb-1">
        <div class="font-weight-bold mr-2">
          Автор изменения:
        </div>
        <div class="text-break">
          {{ getUserFio(user) }}
        </div>
      </div> -->
      <div class="d-flex">
        <div class="font-weight-bold mr-2 ">
          Действия:
        </div>
        <div class="text-break">
          {{ change.action|| '—' }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  format,
  parseISO,
  addHours,
} from '@evd3v/date-fns';

export default {
  name: 'DoctorInfoChangeItem',
  props: {
    change: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {};
  },
  computed: {
    user() {
      return this.change?.user;
    },
  },
  methods: {
    formatDate(date) {
      const dateAdded = addHours(parseISO(date), 3);
      return format(dateAdded, 'dd.MM.yyyy HH:mm');
    },
    // getUserFio(user) {
    //   return `${user?.lastName} ${user?.firstName}`;
    // },
  },
};
</script>
<style lang="scss" scoped>
  .container {
    width: 100%;
    min-height: 70px;
    max-height: 100%;
    display: grid;
    grid-template-columns: 170px 1fr;
    background-color: white;
    box-shadow: 1px 1px 2px 1px rgba(0,0,0,0.14);
    padding: 5px;
  }
  .info{
    display: flex;
    flex-direction: column;
    padding: 5px 10px;
    border-right: 1px solid rgb(233, 229, 229);
  }
  .info-description{
    display: flex;
    flex-direction: column;
    padding: 5px 10px;
  }
</style>
