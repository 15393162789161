<template>
  <b-modal
    visible
    scrollable
    centered
    title="История изменений"
    size="xl"
    hide-footer
    @hidden="onClose"
    @ok="onClose"
  >
    <b-overlay
      :show="isLoading"
      no-wrap
      spinner-variant="primary"
      opacity="0.6"
    />

    <div
      v-if="!isLoading"
      class="changes-wrapper"
    >
      <div
        v-if="history.length > 0"
      >
        <DoctorInfoChangeItem
          v-for="(change, index) in history"
          :key="index"
          :change="change"
        />
      </div>

      <div
        v-else
        class="w-100 bg-white shadow-sm rounded p-4 font-weight-bold"
      >
        Нет данных
      </div>
    </div>
  </b-modal>
</template>

<script>
import { usersService, doctorsService } from '@/services';
import DoctorInfoChangeItem from '@/components/Doctors/DoctorInfoChangeItem';

export default {
  name: 'DoctorHistoryOfChangesModal',
  components: {
    DoctorInfoChangeItem,
  },
  props: {
    modalName: {
      type: [String, Number],
      default: null,
    },
    doctorId: {
      type: [String, Number],
      default: '',
    },
  },
  data() {
    return {
      isLoading: false,
      history: [],
    };
  },
  async created() {
    this.isLoading = true;

    try {
      const result = await doctorsService.getDoctorsHistory(this.doctorId);
      if (result.length) {
        this.history = result?.sort((a, b) => new Date(b.date) - new Date(a.date));
      }

      this.history = await Promise.all(this.history.map(async (change) => ({
        ...change,
        user: await usersService.getUser({ id: change.authorId }),
      })));
    } catch (e) {
      console.error(e);
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    onClose() {
      this.$emit('input', false);
    },
  },
};
</script>

<style scoped>
.changes-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: calc(100vh - 500px);
  max-height: calc(100vh - 160px);
  padding: 0 15px;
}
</style>
